import type { LinksFunction } from '@remix-run/node';
import styles from './app.css?url';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from '@remix-run/react';
import { httpService } from './services/http.service';

type RootData = {
  theme: string;
  headScripts: string[];
  gtmId: string;
  gaId: string;
  grId: string;
  title: string;
  locale: string;
};

export const links: LinksFunction = () => {
  return [
    {
      rel: 'stylesheet',
      href: styles,
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'anonymous',
    },
  ];
};

export async function loader({ params }: any) {
  const data: RootData = {
    theme: process.env.THEME || '',
    headScripts: [],
    gtmId: '',
    gaId: '',
    grId: '',
    title: '',
    locale: params.lang ?? 'en',
  };

  try {
    const url = `${process.env.STRAPI_URL_BASE}/api/meta?populate=deep,2`;
    const meta = await httpService.get(url);
    const json = await meta.json();

    if (json?.data?.attributes?.headScripts?.length > 0) {
      data.headScripts = json.data.attributes.headScripts.map((script: any) => script.script);
    }

    data.gtmId = json.data.attributes.googleTagManagerID;
    data.gaId = json.data.attributes.googleAnalyticsID;
    data.grId = json.data.attributes.googleTagReportID;
    data.title = json.data.attributes.title;
  } catch (error) {
    console.error(error);
  }

  return data;
}

export default function App() {
  const data = useLoaderData<RootData>();

  return (
    <html lang={data.locale}>
      <head>
        <Meta />
        {data.gtmId && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${data.gtmId}');`,
            }}
          ></script>
        )}

        {data.headScripts?.length > 0 &&
          data.headScripts.map((script, index) => <script src={script} key={index} type="text/javascript"></script>)}

        {data.grId && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                function gtag_report_conversion(url) {
                  var callback = function () {
                    if (typeof(url) !== 'undefined') {
                      window.location = url;
                    }
                  };
                  gtag('event', 'conversion', {
                    'send_to': '${data.grId}',
                    'event_callback': callback
                  });
                  return false;
                }
              `,
            }}
          ></script>
        )}

        <Links />
      </head>
      <body id={data.theme} className="page-gradient">
        {data.gtmId && (
          <noscript>
            <iframe
              title="gtm"
              src={`https://www.googletagmanager.com/ns.html?id=${data.gtmId}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          </noscript>
        )}
        {data.gaId && (
          <>
            <script async src={`https://googletagmanager.com/gtag/js?id=${data.gaId}`}></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config",'${data.gaId}');`,
              }}
            ></script>
          </>
        )}

        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
